<template>
  <div class="w-100">
    <div class="content source-caution">
      <el-card class="box-card">
        <div class="mod-title">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item class="b">信源预警</el-breadcrumb-item>
            <el-breadcrumb-item>{{ plan.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="box-shadow"></div>
          <div class="form-box" v-loading="loading.plan">
            <el-form ref="form" label-width="100px" v-if="!loading.plan">
              <el-form-item label="监控时间">
                <el-radio-group v-model="plan.condition.period.value" class="plan-condition mar_t-3">
                  <el-radio label="presently">实时预警</el-radio>
                  <br>
                  <el-radio label="frequency">循环预警</el-radio>
                  <el-select placeholder="每1小时"
                             class="w-120"
                             v-model="plan.condition.period.frequency">
                    <el-option label="每1小时" :value="60"></el-option>
                    <el-option label="每2小时" :value="120"></el-option>
                    <el-option label="每4小时" :value="240"></el-option>
                    <el-option label="每8小时" :value="480"></el-option>
                  </el-select>
                  <br>
                  <el-radio label="timer">定时预警</el-radio>
                  <el-select class="w-120 mar-r-10" v-model="plan.condition.period.timer_setting.num">
                    <el-option label="每日一次" :value="1"></el-option>
                    <el-option label="每日两次" :value="2"></el-option>
                  </el-select>
                  <el-select class="w-120 mar-r-10" v-if="plan.condition.period.timer_setting.num >= 1" v-model="plan.condition.period.timer_setting.one">
                    <el-option :value="hourFormat(index, 'second')"
                               v-for="(h, index) in 24"
                               :key="index"
                               :label="hourFormat(index, 'hour')"
                               :disabled="plan.condition.period.timer_setting.num >= 1 && plan.condition.period.timer_setting.two === hourFormat(index, 'second')">
                    </el-option>
                  </el-select>
                  <el-select class="w-120" v-if="plan.condition.period.timer_setting.num >= 2" v-model="plan.condition.period.timer_setting.two">
                    <el-option :value="hourFormat(index, 'second')"
                               v-for="(h, index) in 24"
                               :key="index"
                               :label="hourFormat(index, 'hour')"
                               :disabled="plan.condition.period.timer_setting.one === hourFormat(index, 'second')">
                    </el-option>
                  </el-select>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="触发条件" class="hide">
                <el-checkbox-group v-model="plan.condition.trigger.value">
                  <el-checkbox label="quantity">
                    <span>信息数量达到</span>
                  </el-checkbox>
                  <el-select v-model="plan.condition.trigger.option.quantity.val" placeholder="20" class="w-120 mar-r-10">
                    <el-option :label="num" :value="num" v-for="(num, index) in plan.condition.trigger.option.quantity.vals" :key="index"></el-option>
                  </el-select>
                  <el-input
                          :max="10000000"
                          type="number"
                          v-show="plan.condition.trigger.option.quantity.val === '自定义'"
                          placeholder="请输入数值"
                          v-model="triggerCustomize.quantity"
                          class="w-200 mar-r-10">
                  </el-input>
                  <span>时报警</span>
                  <el-radio-group v-model="plan.condition.trigger.option.quantity.op" class="mar-r-10">
                    <el-radio label="or">或</el-radio>
                    <el-radio label="and">且</el-radio>
                  </el-radio-group>
                  <br>
                  <el-checkbox label="prev_percent">
                    <span>信息数比前一次检测增加</span>
                  </el-checkbox>
                  <el-select v-model="plan.condition.trigger.option.prev_percent.val" placeholder="100%" class="w-120 mar-r-10">
                    <el-option :label="percent === '自定义' ? '自定义' : `${percent}%`" :value="percent" v-for="(percent, index) in plan.condition.trigger.option.prev_percent.vals" :key="index"></el-option>
                  </el-select>
                  <el-input :max="1000" type="number" v-show="plan.condition.trigger.option.prev_percent.val === '自定义'" v-model="triggerCustomize.prev_percent" placeholder="请输入数值" class="w-200 mar-r-10">
                  </el-input>
                  <span>时报警</span>
                  <el-radio-group v-model="plan.condition.trigger.option.prev_percent.op" class="mar-r-10">
                    <el-radio label="or">或</el-radio>
                    <el-radio label="and">且</el-radio>
                  </el-radio-group>
                  <br>
                  <el-checkbox label="neg_percent_rise">
                    <span>敏感信息占比达到</span>
                  </el-checkbox>
                  <el-select v-model="plan.condition.trigger.option.neg_percent_rise.val" placeholder="20%" class="w-120 mar-r-10">
                    <el-option :label="percent === '自定义' ? '自定义' : `${percent}%`" :value="percent" v-for="(percent, index) in plan.condition.trigger.option.neg_percent_rise.vals" :key="index"></el-option>
                  </el-select>
                  <el-input :max="100" type="number" v-show="plan.condition.trigger.option.neg_percent_rise.val === '自定义'" v-model="triggerCustomize.neg_percent_rise" placeholder="请输入数值" class="w-200 mar-r-10">
                  </el-input>
                  <span>时报警</span>
                  <el-radio-group v-model="plan.condition.trigger.option.neg_percent_rise.op" class="mar-r-10">
                    <el-radio label="or">或</el-radio>
                    <el-radio label="and">且</el-radio>
                  </el-radio-group>
                  <br>
                  <el-checkbox label="neg_percent">
                    <span>敏感信息占比比前一次检测增加</span>
                  </el-checkbox>
                  <el-select v-model="plan.condition.trigger.option.neg_percent.val" placeholder="10%" class="w-120">
                    <el-option :label="percent === '自定义' ? '自定义' : `${percent}%`" :value="percent" v-for="(percent, index) in plan.condition.trigger.option.neg_percent.vals" :key="index"></el-option>
                  </el-select>
                  <el-input :max="100" type="number" v-show="plan.condition.trigger.option.neg_percent.val === '自定义'" v-model="triggerCustomize.neg_percent" placeholder="请输入数值" class="w-200 mar-r-10">
                  </el-input>
                  <span>时报警</span>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="免打扰" class="plan-condition">
                <el-checkbox-group v-model="plan.condition.mute.value" @change="handleTimerangeChange(plan.condition.mute)" class="mar_t-3">
                  <el-checkbox label="push_night">夜间不预警</el-checkbox>
                  <el-select class="w-120 mar-r-10" v-model="plan.condition.mute.push_night[0]" placeholder="请选择" key="time1">
                    <el-option v-for="item in timeRange" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                  <el-select class="w-120" v-model="plan.condition.mute.push_night[1]" placeholder="请选择" key="time2">
                    <el-option v-for="item in timeRange" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                  <span style="color: red; font-size: 12px;" class="hide">不预警时间为第一天22：00至第二天8:00</span>
                  <br>
                  <el-checkbox label="push_week">周末不预警</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="推送方式">
                <el-checkbox-group class="plan-condition mar_t-3" v-model="plan.condition.terminal.value">
                  <div>
                    <el-checkbox label="web" name="type">PC端／从账号列表中选择
                    </el-checkbox>
                    <el-select v-model="plan.condition.terminal.web" multiple collapse-tags placeholder="请选择账号" class="w-200">
                      <el-option v-for="(user, index) in plan.users" :key="index" :label="user.username" :value="user.id" />
                    </el-select>
                    <span class="err-text" v-show="errMsg.web">请选择联系人</span>
                  </div>
                  <div>
                    <el-checkbox label="wechat" name="type">微信端 / 从通讯录中选择
                    </el-checkbox>
                    <el-select v-model="plan.condition.terminal.wechat" @change="wechatContactChange(plan)" multiple collapse-tags
                               :placeholder="plan.condition.terminal.value.indexOf('wechat') !== -1 ? '从通讯录勾选' : '从通讯录勾选'" class="mar-l-20 w-200">
                      <el-option value="new_contact">
                        <template>
                          <i class="iconfont icon-zengjia f-l"></i>
                          <span>新增联系人</span>
                        </template>
                      </el-option>
                      <el-option v-for="(wechat, index) in contacts.wechat" :key="index" :label="wechat.name" :value="wechat.id" />
                    </el-select>
                    <span class="err-text" v-show="errMsg.wechat">请选择联系人</span>
                  </div>
                  <div>
                    <el-checkbox label="email" name="type" :disabled="plan.condition.period.value == 'presently'">邮件通知 / 从通讯录中选择
                    </el-checkbox>
                    <el-select v-model="plan.condition.terminal.email" @change="emailContactChange(plan)" :disabled="plan.condition.period.value == 'presently'" multiple collapse-tags :placeholder="plan.condition.terminal.value.indexOf('email') !== -1 ? '从通讯录勾选' : '从通讯录勾选'" class="w-200">
                      <el-option value="new_contact">
                        <template>
                          <i class="iconfont icon-zengjia f-l"></i>
                          <span>新增联系人</span>
                        </template>
                      </el-option>
                      <el-option v-for="(email, index) in contacts.email" :key="index" :label="email.name" :value="email.id">
                      </el-option>
                    </el-select>
                    <span class="err-text" v-show="errMsg.email">请选择联系人</span>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="推送消息">
                <ul>
                  <li>
                    <span class="mar-r-20 fz-12">信息等级</span>
                    <el-radio-group v-model="plan.condition.term.grade.value">
                      <el-radio label="all">全部</el-radio>
                      <el-radio label="important">重要</el-radio>
                      <el-radio label="super">非常重要</el-radio>
                    </el-radio-group>
                  </li>
                  <li>
                    <span class="mar-r-20 fz-12">情感属性</span>
                    <el-radio-group v-model="plan.condition.term.emotion.value">
                      <el-radio :label="plan.condition.term.emotion.option.join(',')">
                        全部
                      </el-radio>
                      <el-radio label="negative">敏感</el-radio>
                    </el-radio-group>
                  </li>
                </ul>
              </el-form-item>
              <el-form-item label="预警词锁定">
                <div class="f-l mar-r-20">
                  <el-radio-group v-model="plan.condition.focus.value">
                    <el-radio label="all">不限</el-radio>
                    <el-radio label="focus">锁定含</el-radio>
                  </el-radio-group>
                </div>
                <div class="f-l">
                  <span class="fz-12">关键词关系：</span>
                </div>
                <div class="f-l">
                  <div>
                    <el-input placeholder="多个关键词请用逗号分割" v-model="plan.condition.focus.focus.must" class="w-310 mar-r-20"></el-input>
                    <el-radio-group class="yhh" v-model="plan.condition.focus.focus.op">
                      <el-radio label="and">与</el-radio>
                      <el-radio label="or">或</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="mar-t-10">
                    <span class="mar-r-10 fz-12">排除</span>
                    <el-input placeholder="多个关键词请用逗号分割" class="w-276" v-model="plan.condition.focus.focus.not"></el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="副标题">
                <el-input type="text" placeholder="（非必填）请输入副标题" v-model="text" maxlength="30" show-word-limit class="w-430"></el-input>
              </el-form-item>
              <el-form-item label="主题颜色">
                <div v-for="item in themeList"
                     :key="item.label" class="theme_colors mar-t-5">
                  <el-radio v-model="plan.condition.theme_color.value[0]" :label="item.label">{{ item.name }}
                  </el-radio>
                  <span :style="{ background: item.color }" class="item-theme-preview"></span>
                </div>
              </el-form-item>
            </el-form>
            <el-row class="button-group">
              <el-button type="primary" round @click="save(plan)" v-loading="loading.edited">确定</el-button>
              <el-button round @click="toBack()">返回</el-button>
            </el-row>
          </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import {users as customerUsers} from '@/api/customer'
import {warningUpdate} from '@/api/caution'
import {themeList, timeRange} from '@/utils/constants'
import {getContacts} from '@/api/contacts'
const { _ } = window
export default {
  name: 'source-caution',
  data() {
    return {
      themeList,
      timeRange,
      plan: { name: '' },
      contacts: {wechat: [], email: []},
      loading: {edited: false, plan: true},
      text: '',
      triggerCustomize: {
        quantity: '',
        prev_percent: '',
        neg_percent_rise: '',
        neg_percent: ''
      },
      errMsg: {
        web: false,
        wechat: false,
        email: false
      }
    }
  },
  watch: {
    plan: {
      deep: true,
      handler: function () {
        this.checkErrMsg()
      }
    }
  },
  mounted() {
    const route = this.$route
    if (route.params.hasOwnProperty('plan')) {
      const plan = route.params.plan
      this.plan = plan
      this.loading.plan = false
      window.console.log(plan)
      this.planUsers()
      this.loadContact()
    } else {
      this.toBack()
    }
  },
  methods: {
    checkErrMsg() {
      this.errMsg = {
        web: false,
        wechat: false,
        email: false
      }
      const { plan } = this
      if (plan.hasOwnProperty('condition')) {
        for (let i in plan.condition.terminal.value) {
          let terminal = plan.condition.terminal.value[i]
          if (plan.condition.terminal[terminal].length >= 1) {
            this.errMsg[terminal] = false
          }
        }
      }
    },
    loadContact() {
      getContacts().then(res => {
        if (res.data.state) {
          var email = [];
          var wechat = [];
          let result = res.data.data;
          _(result).forEach((item, i) => {
            if (item.email)
              email.push({
                id: item.id,
                email: item.email,
                name: item.name,
              })
            if (item.openid)
              wechat.push({
                id: item.id,
                wechat: item.wechat,
                name: item.name,
              })
          });
          this.contacts.contacts = result;
          this.contacts.email = email;
          this.contacts.wechat = wechat;
        } else {
          this.$message.error(res.data.error);
        }
      }).catch(res => {
        this.$message.error('加载通讯录失败，服务错误');
      });
    },
    wechatContactChange(plan) {
      if (plan.condition.terminal.wechat.indexOf("new_contact") !== -1) {
        this.$refs.contact.show()
        plan.condition.terminal.wechat = plan.condition.terminal.wechat.filter(function (val) {
          return val !== 'new_contact'
        })
      }
    },
    emailContactChange(plan) {
      if (plan.condition.terminal.email.indexOf("new_contact") !== -1) {
        this.$refs.contact.show()
        plan.condition.terminal.email = plan.condition.terminal.email.filter(function (val) {
          return val !== 'new_contact'
        })
      }
    },
    save(data) {
      let plan = JSON.parse(JSON.stringify(data))
      if (plan.condition.period.value == 'timer') {
        var timer = []
        if (!plan.condition.period.timer_setting.num) {
          this.$message.warning('请选择定时周期')
          return false
        }
        if (plan.condition.period.timer_setting.one === '') {
          this.$message.warning('请选择定时时间')
          return false
        }
        timer.push(plan.condition.period.timer_setting.one)
        if (plan.condition.period.timer_setting.num == 2) {
          if (plan.condition.period.timer_setting.two === '') {
            this.$message.warning('请选择定时时间')
            return false
          }
          timer.push(plan.condition.period.timer_setting.two)
        }
        plan.condition.period.timer = timer
      }
      plan.condition.term.grade.value = plan.condition.term.grade.value.split(',')
      plan.condition.term.emotion.value = plan.condition.term.emotion.value.split(',')
      plan.condition.trigger.option.quantity.val = plan.condition.trigger.option.quantity.val === '自定义' ? this.triggerCustomize.quantity : plan.condition.trigger.option.quantity.val
      plan.condition.trigger.option.prev_percent.val = plan.condition.trigger.option.prev_percent.val === '自定义' ? this.triggerCustomize.prev_percent : plan.condition.trigger.option.prev_percent.val
      plan.condition.trigger.option.neg_percent_rise.val = plan.condition.trigger.option.neg_percent_rise.val === '自定义' ? this.triggerCustomize.neg_percent_rise : plan.condition.trigger.option.neg_percent_rise.val
      plan.condition.trigger.option.neg_percent.val = plan.condition.trigger.option.neg_percent.val === '自定义' ? this.triggerCustomize.neg_percent : plan.condition.trigger.option.neg_percent.val
      plan.condition.trigger.option.quantity.val = plan.condition.trigger.option.quantity.val >= 10000000 ? 10000000 : plan.condition.trigger.option.quantity.val
      plan.condition.trigger.option.prev_percent.val = plan.condition.trigger.option.prev_percent.val >= 1000 ? 1000 : plan.condition.trigger.option.prev_percent.val
      plan.condition.trigger.option.neg_percent_rise.val = plan.condition.trigger.option.neg_percent_rise.val >= 100 ? 100 : plan.condition.trigger.option.neg_percent_rise.val
      plan.condition.trigger.option.neg_percent.val = plan.condition.trigger.option.neg_percent.val >= 100 ? 100 : plan.condition.trigger.option.neg_percent.val
      for (let i = 0; i < plan.condition.trigger.value.length; i++) {
        let option = plan.condition.trigger.value[i]
        if (plan.condition.trigger.option[option].val === '') {
          this.$message.warning("请输入触发条件自定义数值")
          return false
        }
      }
      if (_.size(plan.condition.terminal.value) < 1) {
        this.$message.warning('请选择预警推送方式')
        return false
      }
      for (let i in plan.condition.terminal.value) {
        var terminal = plan.condition.terminal.value[i]
        if (_.size(plan.condition.terminal[terminal]) < 1) {
          // this.$message.warning('请选择' + terminal + '预警推送方式联系人')
          this.errMsg[terminal] = true
          return false
        }
      }
      if (plan.condition.period.value == 'presently') {
        plan.condition.terminal.value = plan.condition.terminal.value.filter(function (val) {
          return val != 'email'
        })
        plan.condition.terminal.email = []
      }
      if (plan.condition.subtitle.value[0] === 'show_title' && !plan.condition.subtitle.show_title[0]) {
        this.$message.error('请填写副标题内容')
        return false
      }
      plan.condition.subtitle.show_title = plan.condition.subtitle.value[0] === 'show_title' ? plan.condition.subtitle.show_title : []
      var newPlan = JSON.parse(JSON.stringify(plan))
      delete newPlan.name
      delete newPlan.checkAll
      delete newPlan.createdby
      delete newPlan.edited
      newPlan.enable = newPlan.enable == 1 ? true : false
      delete newPlan.isIndeterminate
      delete newPlan.condition.period.timer_setting
      delete newPlan.where
      delete newPlan.users
      this.loading.edited = true
      warningUpdate(newPlan).then(res => {
        if (res.data.state) {
          this.$message.success('更新方案预警成功')
          this.$router.push("/source?setTab=caution");
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.edited = false
      }).catch(res => {
        this.loading.edited = false
        this.$message.error('保存失败，服务错误')
      })
    },
    handleTimerangeChange(item) {
      const {value} = item;
      if (value.indexOf('push_night') > -1) {
        item.push_night = ['22:00', '08:00']
      } else {
        item.push_night = ['', '']
      }
    },
    hourFormat(hour, type) {
      var val = ''
      switch (type) {
        case 'second':
          val = parseInt(hour) * 3600
          break;
        case 'hour':
          val = hour < 10 ? '0' + hour + ':00:00' : hour + ':00:00'
          break;
        default:
          window.console.error('format type not allowed')
      }
      return val
    },
    toBack() {
      this.$router.back(-1)
    },
    planUsers() {
      const { plan } = this
      customerUsers({plan_id: plan.plan_id}).then(res => {
        if (res.data.state) {
          plan.users = res.data.data
          let uids = plan.users.map((item, index) => {
            return item.id
          })
          // 过滤已删除用户ID
          plan.condition.terminal.web = plan.condition.terminal.web.filter(id => {
            return uids.indexOf(id) !== -1
          })
          window.console.log(plan.users)
        } else {
          this.$message.error(res.data.error)
        }
      }).catch(() => {
        this.$message.error('加载方案用户失败，服务错误')
      })
    }
  }
};
</script>
<style scoped>
.source-caution .form-box{background: none;}
.source-caution >>> .el-form-item{line-height: 37px;}
.source-caution >>> .el-form-item{margin-bottom: 10px;}
.w-276{width: 276px}
.plan-condition >>> .el-radio,.plan-condition >>> .el-checkbox{margin-right: 10px;line-height: 37px;margin-bottom: 10px;}
.source-caution >>> .plan-condition{margin-bottom: 0;}
.err-text {font-size: 14px;color: red;margin-left: 10px;}
</style>
